<template>
    <div class="success">
        <div class="img">
            <img src="../assets/error.png" alt="success">
        </div>
        <p>标签验证出现问题，请重试.</p>
        <button class="btn" @click="backToPage">
            重试
            <i class="iconfont icon-right"></i>
        </button>
    </div>
</template>
  
<script>
export default {
    name: 'VerifyFailedView',
    methods: {
        backToPage() {
            this.$router.go(-1);
        }
    }
}
</script>
<style scoped lang="scss">
.success {
    margin: 0;
    padding: 0;
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 20% 20px;

    .img {
        width: 20%;

        img {
            width: 100%;
        }

        margin-bottom: 2em;
    }

    p {
        margin: 0px;
        font-size: 28px;
        text-align: center;
    }

    .btn {
        padding: 15px 20px;
        margin-top: 2em;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}</style>