<template>
    <div class="home">
        <div class="not-active" v-if="!isActive" style="text-align: center;">
            {{ loadingText }}
        </div>
        <div class="bg" v-if="isActive"></div>
        <div class="header" v-if="isActive">
            <div class="row"><img class="logo" src="../../images/logo.png" alt="logo" /></div>
            <div class="tip">
                <span>感谢您支持唯他时光官方正品</span>
            </div>
            <div class="navTitle">
                <span class="label">{{ activeSection }}</span>
                <span class="t">全程透明追溯 安全放心每一步</span>
            </div>
        </div>
        <div class="content">
            <div class="verify-item">
                <span class="title">防伪查询中心</span>
                <div class="input-box">
                    <span>{{ security_code }}</span>
                    <input type="number" v-model="security_code_last" placeholder="输入防伪码后4位查询真伪" maxlength="4"/>
                    <button @click="verifyCode">查询</button>
                </div>
            </div>
            <div class="result-item" v-if="verifyed">
                <div class="result">
                    <span class="b">恭喜：该码验证通过！</span>
                    <span class="b">您查询的是正品编码，请放心购买使用</span>
                    <span class="line"></span>
                    <span>当前是第<b>{{ queryResult.queryCount }}</b>次被查询</span>
                    <span>首次查询时间为：<b>{{ queryResult.firstQueryDate }}</b></span>
                </div>
            </div>
            <div class="tips">
                <img src="../../images/tips.jpg" alt="">
            </div>
        </div>
    </div>
</template>

<script>
import verifyService from '../../services/verifyService';
export default {
    name: 'HomeView',
    data() {
        return {
            activeSection: '防伪验证', // 初始激活的部分
            isScrollingProgrammatically: false, // 添加标志
            pageData: {},
            isActive: false,
            showTestReport: false,
            loadingText: '加载中...',
            classService: new verifyService(),
            security_code: '',
            security_code_last: '',
            verifyed: false,
            queryResult: {
                queryCount: 0,
                firstQueryDate: ''
            }
        }
    },
    methods: {
        LoadData() {
            this.classService.verifyCode({
                "barcode": this.$route.query.c,
                "action": "checkBarcode"
            }).then((res) => {
                this.isActive = true;
                this.security_code = res.message.security_code;
            }).catch(() => {
                this.isActive = false;
                this.$router.push('/verifyFailed');
            });
        },
        verifyCode() {
            if (this.security_code_last.length != 4) {
                alert('请输入4位验证码');
                return;
            }

            this.classService.verifyCode({
                "barcode": this.$route.query.c,
                "security_code": this.security_code + this.security_code_last,
                "action": "verifyBarcodeNotXinma"
            }).then((res) => {
                console.log(res);
                if(res.resultCode==3){
                    this.$router.push('/verifyFailed');
                    return;
                }
                this.verifyed = true;
                this.queryResult = res.message;
            }).catch(() => {
                this.verifyed = false;
                this.$router.push('/verifyFailed');
            });
        }

    },
    mounted() {
        this.LoadData();
    }
}
</script>


<style lang="scss">
.home {
    padding-top: 200px;
    padding-bottom: 0;
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
}

.bg {
    background: linear-gradient(#7dcace, white, white, white);
    position: fixed;
    z-index: -1;
    top: 200px;
    left: 0;
    width: 100%;
    height: 100%;
}

.header {
    background-size: 100% 100%;
    background-image: url('../../images/topBg.png');
    position: fixed;
    left: 0;
    top: 0;
    z-index: 99;
    display: flex;
    height: 200px;
    width: 100%;
    padding: 10px;
    display: flex;
    flex-direction: column;

    .row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
    }

    .title {
        color: #fff;
        text-align: center;
        font-size: 22px;
        margin-bottom: 10px;
    }

    .tip {
        display: flex;
        align-items: center;
        color: white;
        justify-content: center;

        span {
            padding: 4px 7px;
            border-radius: 25px;
            border: 1px solid white;
            font-size: 16px;
            letter-spacing: .13rem;
        }
    }

    .navTitle {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: white;
        font-size: 14px;
        position: absolute;
        padding: 0 20px;
        width: 100%;
        bottom: 0;
        left: 0;
        height: 40px;
        letter-spacing: .13rem;
        background-color: rgba(94, 154, 163, 0.7);

        .label {
            font-size: 16px;
            background: linear-gradient(#ffb193, #ea5514);
            padding: 5px 15px;
            height: 40px;
            display: flex;
            align-items: center;
            position: absolute;
            top: -10px;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;

            &:after {
                content: "";
                position: absolute;
                left: -10px;
                top: -10px;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 10px 0px 10px 10px;
                border-color: transparent transparent #9f3d13 transparent;
            }

            &:before {
                content: "";
                position: absolute;
                right: -10px;
                top: 0px;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 0px 10px 10px 0px;
                border-color: transparent transparent #9f3d13 transparent;
            }
        }

        .t {
            margin-left: auto;
        }
    }

    .logo {
        width: 100px;
    }
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: white;
    flex: 1;

    .verify-item {
        display: flex;
        justify-content: center;
        padding: 30px;
        background-color: #eee;
        flex-direction: column;
        align-items: center;

        .title {
            font-size: 24px;
            font-weight: bold;
            color: #059192;
            margin-bottom: 10px;
        }

        .input-box {
            display: flex;
            align-items: center;
            border-radius: 5px;
            background-color: white;
            height: 40px;
            box-shadow:
                3px 3px 10px rgba(0, 0, 0, 0.05);

            span {
                font-size: 14px;
                color: #6f6f6f;
                padding: 0 10px;
                font-weight: bold;
            }

            input {
                border: none;
                height: 100%;
                margin-right: 10px;
                flex: 1;
                font-size: 14px;
                font-weight: bold;

                &:focus {
                    outline: none;
                }
            }

            button {
                background-color: #059192;
                color: white;
                border: none;
                height: 100%;
                border-radius: 5px;
                padding: 0 20px;
                font-size: 14px;
                font-weight: bold;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                cursor: pointer;

                &:focus {
                    outline: none;
                }
            }
        }
    }

    .result-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px 0;
        width: 100vw;

        .result {
            width: 80%;
            display: flex;
            flex-direction: column;
            background: #eee;
            padding: 20px;
            font-size: 14px;

            .b {
                font-weight: bold;
            }

            .line {
                margin-bottom: 10px;
            }

            b {
                color: red;
            }
        }


    }

    .tips {
        width: 90%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 20px;

        span {
            font-size: 14px;
            margin-bottom: 10px;
        }

        img {
            width: 100%;
        }
    }
}


.hide {
    visibility: hidden;
}
</style>