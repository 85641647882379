import ApiService from '../utils/apiService';
class HomeService {
    constructor() {
        this.apiService = new ApiService();
    }

    getData(data) {
        return this.apiService.post('/api/app/getBarcodeDetails', data, true)
    }
}

export default HomeService;
