<template>
    <div class="scanner">
        <scanner @success='qrcodeSucess' @error="qrcodeError" :paused-scan="verifying"></scanner>
        <div class="toast" v-if="showMsg">
            {{ toastText }}
        </div>
    </div>
</template>
<script>

import scanner from '../components/xmScanner/xmScanner.vue';
import verifyService from '../services/verifyService';
export default {
    name: 'ScannerView',
    data() {
        return {
            inThrottle: false,
            classService: new verifyService(),
            toastText: 'test',
            showMsg: false,
            verifyed: false,
            verifying:false,
            verifyCount: 0,
            verifyedCount: 0,
            inserted: false,
            barcode: ""
        };
    },
    components: {
        scanner
    },
    methods: {
        
        showToast(text) {
            this.toastText = text;
            this.showMsg = true;
            setTimeout(() => {
                this.showMsg = false;
            }, 3000);
        },
        throttle(func, limit) {
            return function () {
                const args = arguments;
                const context = this;
                if (!this.inThrottle) {
                    func.apply(context, args);
                    this.inThrottle = true;
                    setTimeout(() => this.inThrottle = false, limit);
                }
            }
        },
        qrcodeSucess(data) {
            if (this.verifyed) {
                return;
            }
            this.verifying = true;
            this.postData(data.image, data.barcode);
        },
        qrcodeError(err) {
            console.log(err)
        },
        postData(base64Data, barcode) {
            console.log(barcode);
            this.classService.verifyCode({
                barcodeImg: base64Data,
                barcode: barcode,
                companyCode: "0000"
            }).then((res) => {
                console.log(res)
                if (this.verifyed) {
                    return;
                }
                switch (res.resultCode) {
                    case 0:
                        this.verifyed = true;
                        this.barcode = barcode;
                        this.verifyedCount = res.verifyCount;
                        this.insertLog();
                        break;
                    case 1:
                    case 2:
                    case 3:
                        this.verifying = false;
                        this.verifyCount++;
                        if (this.verifyCount >= 10) {
                            this.verifyed = true;
                            this.$router.push('/error');
                        }
                        break;
                    case 4:
                        this.verifyed = true;
                        this.$router.push('/error?exceedCountLimit=true');
                        break;
                }
            }).catch((err) => {
                console.log(err);
                if (this.verifyed) return;
                this.verifyed = true;
                this.$router.push('/verifyFailed');
            });
        },
        insertLog() {
            console.log('insert log');
            console.log(localStorage.getItem('barcode'));
            if (this.inserted) return;
            this.inserted = true;

            this.classService.verifyCode({
                action: "insertLog",
                location: "",
                barcode: this.barcode,
                query_result: "success",
                query_ip: ""
            }).then((res) => {
                console.log(res)
                if (res.resultCode == 0) {
                    console.log('insert log success');
                    this.verifyedCount++;
                    console.log(this.verifyedCount);
                    this.$router.push('/success?vc=' + this.verifyedCount);
                }
            }).catch((err) => {
                console.log(err);
                if (this.verifyed) return;
                this.verifyed = true;
                this.$router.push('/verifyFailed');
            });
        }
    }
}
</script>
<style scoped lang="scss">
html,
body {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    overflow: hidden;
}

.scanner {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.toast {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    padding: 10px;
    border-radius: 5px;
    z-index: 999;
}
</style>