<template>
    <div class="success">
        <div class="img">
            <img src="../assets/error.png" alt="success">
        </div>
        <p v-if="!exceedCountLimit">标签验证 <b style="font-style: italic;">失败</b> 请重试或者联系销售. </p>

        <p v-if="!exceedCountLimit"></p>
        <p v-if="exceedCountLimit">当前标签验证次数已超过最大次数.</p>
        <button class="btn" @click="backToPage">
            重试
            <i class="iconfont icon-right"></i>
        </button>
        <p class="tips" v-if="!exceedCountLimit">可尝试点击<b>1x</b>，<b>2x</b>，<b>3x</b>，<b>4x</b>调整放大倍率后验证。</p>
    </div>
</template>

<script>
export default {
    name: 'ErrorView',
    data() {
        return {
            exceedCountLimit: false
        }
    },
    mounted() {
        this.exceedCountLimit = this.$route.query.exceedCountLimit;
    },
    methods: {
        backToPage() {
            this.$router.go(-1);
        }
    }
}
</script>
<style scoped lang="scss">
.success {
    margin: 0;
    padding: 0;
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 20% 20px;

    .img {
        width: 20%;

        img {
            width: 100%;
        }

        margin-bottom: 2em;
    }

    p {
        text-align: center;
        font-size: 28px;
        margin: 0;
    }

    .tips {
        font-size: 12px;
        text-align: left;
        width: 100%;
        margin-top: 1em;
        b{
            color:cornflowerblue;
        }
    }

    .btn {
        padding: 15px 20px;
        margin-top: 2em;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
</style>