import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
// 导入组件
import ScannerView from '../views/ScannerView.vue';
import SuccessView from '../views/SuccessView.vue';
import ErrorView from '../views/ErrorView.vue';
import VerifyFailedView from '../views/VerifyFailedView.vue';
import VerifyView from '../views/verify/VerifyView.vue';


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: { title: '唯他时光防伪查询中心' }
  },
  {
    path: '/pages/c/index',
    name: 'home',
    component: HomeView,
    meta: { title: '唯他时光防伪查询中心' }
  },
  {
    path: '/scanner',
    component: ScannerView,
    meta: { title: '唯他时光防伪查询中心' }
  },
  {
    path: '/success',
    component: SuccessView,
    meta: { title: '唯他时光防伪查询中心' }
  },
  {
    path: '/error',
    component: ErrorView,
    meta: { title: '唯他时光防伪查询中心' }
  },
  {
    path: '/verifyFailed',
    component: VerifyFailedView,
    meta: { title: '唯他时光防伪查询中心' }
  },
  {
    path: '/verify',
    component: VerifyView,
    meta: { title: '唯他时光防伪查询中心' }
  },
]


const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  // 从路由的 meta 字段中获取标题
  const title = to.meta.title;
  if (title) {
    document.title = title; // 设置页面标题
  }
  next(); // 继续导航
})

export default router
