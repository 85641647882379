<template>
  <div class="home">
    <div class="not-active" v-if="!isActive" style="text-align: center;">
      {{ loadingText }}
    </div>
    <div class="bg" v-if="isActive"></div>
    <div class="header" v-if="isActive">
      <div class="row"><img class="logo" src="../images/logo.png" alt="logo" /></div>
      <div class="title">
        <span>追溯产品：<b>{{ pageData.product_name }}</b></span>
      </div>
      <div class="tip">
        <span>感谢您支持唯他时光官方正品</span>
      </div>
      <div class="navTitle">
        <span class="label">{{ activeSection }}</span>
        <span class="t">全程透明追溯 安全放心每一步</span>
      </div>
    </div>
    <div class="content" v-if="isActive">
      <div class="product-info" ref="productInfo">
        <div class="left">
          <img :src="pageData.additional_info.product_image[0]" alt="">
        </div>
        <div class="right">
          <p class="name">{{ pageData.product_name }}</p>
          <p><b>产品类别：</b>{{ pageData.product_category }}</p>
          <p><b>净含量：</b>{{ pageData.net_weight }}</p>
          <!-- <p><b>含量：</b>{{ pageData.content_per_piece }}</p> -->
          <p><b>生产批号：</b>{{ pageData.batch_no }}</p>
          <p><b>生产日期：</b>{{ new Date(pageData.production_date).toLocaleDateString() }}</p>
          <span class="jiance-btn" @click="showTestReport = true">检测报告</span>
        </div>
        <div class="stext">
          保质期剩余天数：<span><b :key="index" v-for="(item, index) in formattedDays">{{ item }}</b></span>
        </div>
      </div>
      <div class="product-img" ref="productImg">
        <img :src="item" :key="index" v-for="(item, index) in pageData.additional_info.product_intro_image" alt="">
      </div>
      <div class="process" ref="process">
        <div v-for="(item, index) in pageData.steps" :key="index" class="process-item">
          <!-- 奇数行内容 -->
          <div v-if="index % 2 === 0" class="p-info">
            <div>
              <div class="ptitle">
                <span>{{ item.name }}</span>
              </div>
              <p>{{ item.description }}</p>
              <video controls :src="item.video_url" :class="{'hide':!item.video_url}"></video>
            </div>

          </div>
          <div v-if="index % 2 === 0" :class="{ 'hide': index >= pageData.steps.length - 1 }" class="p-img">
            <img src="../images/tright.png" alt="">
          </div>
          <div v-if="index % 2 != 0" :class="{ 'hide': index >= pageData.steps.length - 1 }" class="p-img">
            <img src="../images/tleft.png" alt="">
          </div>
          <div v-if="index % 2 != 0" class="p-info">

            <div class="ptitle">
              <span>{{ item.name }}</span>
            </div>
            <p>{{ item.description }}</p>
            <video controls :src="item.video_url" :class="{'hide':!item.video_url}"></video>
          </div>

        </div>

      </div>
      <div class="jiance" ref="jiance">
        <img :src="item" :key="index" v-for="(item, index) in pageData.additional_info.product_standard_report_image"
          alt="">
      </div>
    </div>
    <div class="footer" v-if="isActive">
      <div :key="index" v-for="(item, index) in footerList"
        :class="{ 'f-btn': true, 'active': activeSection === item.text }" @click="scrollToSection(item.text)"
        class="f-btn">
        <i class="iconfont" :class="`icon-${item.icon}`"></i>
        <span>{{ item.text }}</span>
      </div>
    </div>
    <div class="jiancebaogao" @click="showTestReport = false" v-if="showTestReport">
      <img :src="item" :key="index" v-for="(item, index) in pageData.test_report_image" alt="">
    </div>
  </div>
</template>

<script>
import HomeService from '../services/HomeService';
export default {
  name: 'HomeView',
  data() {
    return {
      classService: new HomeService(),
      activeSection: '产品档案', // 初始激活的部分
      footerList: [
        { text: '产品档案', icon: 'dangan' },
        { text: '成分探源', icon: 'chengfen' },
        { text: '防伪验证', icon: 'scanning' },
        { text: '生产流程', icon: 'liucheng' },
        { text: '产品标准', icon: 'chanpin' }
      ],
      isScrollingProgrammatically: false, // 添加标志
      pageData: {},
      isActive: false,
      showTestReport: false,
      loadingText: '加载中...'
    }
  },
  computed: {
    remainingDays() {
      const now = new Date();
      const target = new Date(this.pageData.shelf_life);
      const diff = target - now;
      const days = Math.ceil(diff / (1000 * 60 * 60 * 24));
      return days > 0 ? days : 0;  // 防止出现负数
    },
    formattedDays() {
      // 使用toString().padStart确保数字总是四位，不足部分前面补0
      // split('')将字符串转换为字符数组
      return this.remainingDays.toString().padStart(4, '0').split('');
    }
  },
  methods: {
    LoadData() {
      this.classService.getData({
        barcode: this.$route.query.c
      }).then(res => {
        console.log(res);
        this.pageData = res.data;
        document.title = res.data.page_title;
        this.isActive = true;
      }).catch(() => {
        this.isActive = false;
        this.loadingText = '加载失败或者标签未激活！';
      })
    },
    handleScroll() {
      if (this.isScrollingProgrammatically) {
        return; // 如果是程序控制的滚动，不进行处理
      }
      const sections = {
        'product-info': '产品档案',
        'product-img': '成分探源',
        'process': '生产流程',
        'jiance': '产品标准'
      };
      let foundSection = '产品档案'; // 默认部分
      for (let key in sections) {
        const element = document.querySelector(`.${key}`);
        if (element) {
          const bound = element.getBoundingClientRect();
          if (bound.top <= 200 && bound.bottom >= 200) {
            foundSection = sections[key];
            break;
          }
        }
      }
      this.activeSection = foundSection;
    },

    scrollToSection(section) {
      if (section === '防伪验证') {
        this.$router.push({ path: '/scanner' });
        return;
      }

      const refs = {
        '产品档案': 'productInfo',
        '成分探源': 'productImg',
        '生产流程': 'process',
        '产品标准': 'jiance'
      };
      const refName = refs[section];
      if (this.$refs[refName]) {
        const element = this.$refs[refName];
        const yOffset = -190;
        const y = element.getBoundingClientRect().top + window.scrollY + yOffset;
        this.isScrollingProgrammatically = true; // 设置标志为 true
        window.scrollTo({ top: y});
        this.activeSection = section; // 确保设置正确的激活部分
        this.isScrollingProgrammatically = false;
        // // 设置一个延时来重置标志
        // setTimeout(() => {
        //   this.isScrollingProgrammatically = false;
        // }, 500); // 确保滚动足够时间完成
      }
    },


  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
    this.pageType= this.$route.query.t;
    if(this.pageType === '1')
      this.$router.push({ path: '/verify?c='+this.$route.query.c });
    this.LoadData();
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  }
}
</script>


<style lang="scss">
.home {
  padding-top: 200px;
  padding-bottom: 100px;
}

.bg {
  background: linear-gradient(#7dcace, white, white, white);
  position: fixed;
  z-index: -1;
  top: 200px;
  left: 0;
  width: 100%;
  height: 100%;
}

.header {
  background-size: 100% 100%;
  background-image: url('../images/topBg.png');
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99;
  display: flex;
  height: 200px;
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;

  .row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  .title {
    color: #fff;
    text-align: center;
    font-size: 22px;
    margin-bottom: 10px;
  }

  .tip {
    display: flex;
    align-items: center;
    color: white;
    justify-content: center;

    span {
      padding: 4px 7px;
      border-radius: 25px;
      border: 1px solid white;
      font-size: 12px;
      letter-spacing: .13rem;
    }
  }

  .navTitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: white;
    font-size: 14px;
    position: absolute;
    padding: 0 20px;
    width: 100%;
    bottom: 0;
    left: 0;
    height: 40px;
    letter-spacing: .13rem;
    background-color: rgba(94, 154, 163, 0.7);

    .label {
      font-size: 16px;
      background: linear-gradient(#ffb193, #ea5514);
      padding: 5px 15px;
      height: 40px;
      display: flex;
      align-items: center;
      position: absolute;
      top: -10px;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;

      &:after {
        content: "";
        position: absolute;
        left: -10px;
        top: -10px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 10px 0px 10px 10px;
        border-color: transparent transparent #9f3d13 transparent;
      }

      &:before {
        content: "";
        position: absolute;
        right: -10px;
        top: 0px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0px 10px 10px 0px;
        border-color: transparent transparent #9f3d13 transparent;
      }
    }

    .t {
      margin-left: auto;
    }
  }

  .logo {
    width: 100px;
  }
}

.content {
  display: flex;
  flex-direction: column;

  .product-info {
    margin: 10px;
    background: linear-gradient(to bottom right, #7dcace, #fff, #fff);
    padding: 20px;
    border-radius: 10px;
    min-height: 200px;
    display: flex;
    flex-wrap: wrap;

    .left {
      width: 50%;

      img {
        width: 100%;
      }
    }

    .right {
      width: 45%;
      margin-left: 15px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .name {
        color: #ea5514;
      }

      h3 {
        font-size: 16px;
        margin-bottom: 2px
      }

      p {
        font-size: 12px;
        margin-bottom: 5px;
        margin-top: 5px;
        color: #495152;

        b {
          color: black;
        }
      }

      .jiance-btn {
        padding: 5px 10px;
        border-radius: 3px;
        background: linear-gradient(#02c7ca, #0a6e81);
        font-size: 12px;
        color: white;
        margin-top: 10px;
        text-align: center;
      }
    }

    .stext {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      color: #11a3b0;
      margin-top: 20px;

      span {
        font-size: 14px;
        text-align: center;

        b {
          font-size: 20px;
          margin: 0 5px;

          border: 2px solid #ea5514;
          padding: 0px 5px;
        }
      }
    }
  }

  .product-img {
    width: 100vw;
    img {
      width: 100%;
    }
  }

  .process {
    display: flex;
    flex-direction: column;
    padding: 20px;

    .process-item {
      display: flex;
      justify-content: space-between;
      margin-bottom: 50px;

      &:last-child {
        margin: 0;
      }

      .p-info {
        width: 70%;

        .ptitle {
          border: 1px solid #11a3b0;
          display: inline-block;
          border-radius: 25px;
          padding: 2px;

          span {
            background-color: #ea5514;
            border-radius: 25px;
            display: block;
            padding: 5px 10px;
            color: white;
            font-weight: bold;
            width: 80px;
            text-align: center;
          }

        }

        p {
          color: #9d9d9e;
          font-size: 12px;
        }

        video {
          width: 100%;
        }
      }

      .p-img {
        width: 30%;
        display: flex;
        justify-content: center;
        position: relative;

        img {
          width: 40px;
          position: absolute;
          bottom: -40px;
        }
      }
    }
  }

  .jiance {
    width: 100vw;
    img {
      width: 100%;
    }
  }
}

.footer {
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  background-image: url('../images/navBg.png');
  height: 80px;
  background-size: 100% 100%;
  background-position-y: 20px;
  background-repeat: no-repeat;
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;

  .f-btn {
    width: 25%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 12px;

    i {
      font-size: 24px;
      width: 48px;
      height: 48px;
      border-radius: 50%;
      background: linear-gradient(to bottom right, #5be6e0, #319ca7);
      border: 1px solid #fff;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &.active {
      i {
        background: linear-gradient(to bottom right, #ea5514, #ff9b4f);
      }
    }
  }
}

.hide {
  visibility: hidden;
}

.jiancebaogao {
  overflow: auto;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  padding: 30px;
  z-index: 999;
  background-color: rgba(0, 0, 0, .5);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  img {
    width: 100%;
    margin-bottom: 20px;
  }
}</style>
