<template>
    <div class="success">
        <div class="img">
            <img src="../assets/success.png" alt="success">
        </div>
        <p>您查询的标签为唯他时光正品编码，请放心使用！</p>
        <p style="margin-top: 3em;">当前标签是第 <b>{{ verifyedCount }}次</b> 被验证. </p>
    </div>
</template>
  
<script>
import verifyService from '../services/verifyService';
export default {
    name: 'SuccessView',
    data() {
        return {
            classService: new verifyService(),
            verifyedCount: 0
        }
    },
    mounted() {
        this.verifyedCount = this.$route.query.vc;
    },
    methods: {
        
    }
}
</script>
<style scoped lang="scss">
.success {
    margin: 0;
    padding: 0;
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 20% 20px;

    .img {
        width: 20%;

        img {
            width: 100%;
        }

        margin-bottom: 2em;
    }

    p {
        margin: 0px;
        text-align: center;
        font-size: 28px;
    }
}
</style>